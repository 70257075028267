import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('h1',{staticClass:"pageHeader my-12"},[_vm._v("Blocked Account")]),_c('div',{staticClass:"mb-12 d-flex"},[_c(VBtn,{staticClass:"tabBtn"},[_vm._v(" Account ("+_vm._s(0)+") ")]),_c('div',{staticClass:"ml-auto"},[_c(VMenu,{attrs:{"dark":"","left":"","z-index":"0","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"ml-auto align-self-center"},[_c(VIcon,_vm._g({staticClass:"icon",attrs:{"color":"white"}},on),[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.isSelect = true}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Unblock")])],1)],1)],1)],1)],1),_c('div',{staticClass:"mt-5"},[_vm._v("("+_vm._s(0)+")")])])}
var staticRenderFns = []

export { render, staticRenderFns }