<template>
  <div class="content-wrapper">
    <h1 class="pageHeader my-12">Blocked Account</h1>
    <div class="mb-12 d-flex">
      <v-btn class="tabBtn"> Account ({{ 0 }}) </v-btn>
      <div class="ml-auto">
        <v-menu dark left z-index="0" offset-y>
          <template v-slot:activator="{ on }">
            <div class="ml-auto align-self-center">
              <v-icon color="white" class="icon" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </div>
            
          </template>
          <v-list>
            <v-list-item @click="isSelect = true">
              <v-list-item-title class="px-8">Unblock</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mt-5">({{ 0 }})</div>
  </div>
</template>

<script>
  import {
    PROFILE_INITIAL_GET_BLOCKED_USERS_STATE,
    PROFILE_GET_BLOCKED_USERS
  } from '@/store/profile.module';

  export default {
    name: 'BlockedAccount',
    data: () => ({
      lists: [],
      oriLists: [],
      search: '',
      isSelect: false
    }),
    mounted() {
      this.getBlockedUsers();
    },
    computed: {
      blockedUsersComplete() {
        return this.$store.state.profile.blockedUsers.complete;
      }
    },
    watch: {
      blockedUsersComplete() {
        let response = this.$store.state.profile.blockedUsers;
        if (response.complete) {
          this.importBlockedUsersList(response);
        }
      }
    },
    methods: {
      importBlockedUsersList(response) {
        this.lists = response.data;
        this.oriLists = response.data;
        if (this.lists) {
          this.blockedUsersCompleteAction();
        }
      },
      blockedUsersCompleteAction() {
        this.initialBlockedUsersState();
      },
      initialBlockedUsersState() {
        this.$store.dispatch(PROFILE_INITIAL_GET_BLOCKED_USERS_STATE);
      },
      getBlockedUsers() {
        this.$store.dispatch(PROFILE_GET_BLOCKED_USERS);
      }
    }
  };
</script>

<style></style>
